import styled from '@emotion/styled';
import {
  ModularPage
} from '@paddl/storybook';
import { hardRedirect } from '@paddl/utils-js';
import {
  ExperiencePageContentWrapper,
  ExperienceSidebarContent,
  useCompleteActivity,
  useResource,
  withDataFetching,
  withDataSubmission,
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import {
  shape,
  string
} from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'recompose';

export const LiveEventContainer = styled.div`
margin-top: ${({ width }) => (width === 'xs' ? '0' : '-60px')};
  padding-top: 60px;
  background-size: cover;
  background-position: center;
`;

export const ExperienceComp = ({
  match: {
    params: { organisationId, programId, experienceId }
  }
}) => {
  const completeActivityData  = useCompleteActivity({ experienceId });

  const url = `${process.env.AT_WORK_API}/v2/experience/${experienceId}`;

  const { data, isLoading } = useResource(url);

  useEffect(() => {
    if (window?.analytics && data?.experience) {
      window.analytics.page(
        'Workspace App',
        'Experience',
        {
          experienceId,
          experienceTitle: data.experience.title,
          activityType: data.experience.activityType,
          programId,
          workspaceId: organisationId
        });
    }
  }, [data, organisationId, programId, experienceId]);

  const breadcrumbItems = [
    {
      title: 'Workspace',
      action: () => {
        hardRedirect('workspaceById', { id: organisationId });
      }
    },
    {
      title: 'Program',
      action: () => {
        hardRedirect('workspaceProgramById', { organisationId, programId });
      }
    },
    {
      title: 'Experience'
    }
  ];

  return (
    <ModularPage
      pageName={'Experience'}
      pageContent={<ExperiencePageContentWrapper experience={data?.experience} isLoading={isLoading} completeActivityData={completeActivityData} breadcrumbItems={breadcrumbItems} />}
      sidebarContent={<ExperienceSidebarContent
        experience={data?.experience}
        isComplete={completeActivityData.isComplete}
        activity={completeActivityData.activity}
      />}
      navigationContent={<MemberAppNavigation_Populated currentPage="workspace" />}
      breadcrumbItems={breadcrumbItems}
    />
  );
};

ExperienceComp.propTypes = {
  match: shape({
    params: shape({
      organisationId: string.isRequired,
      programId: string.isRequired,
      experienceId: string.isRequired
    }).isRequired
  }).isRequired
};

export const Experience = compose(
  withDataFetching({ propNameWrapper: 'getExperience' }),
  withDataSubmission({ propNameWrapper: 'createActivity' })
)(ExperienceComp);
