import {
  ModularPage
} from '@paddl/storybook';
import {
  MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import {
  shape,
  string
} from 'prop-types';
import React from 'react';
import { GatewayContent_NEON } from './GatewayContent_NEON';

export const Gateway = ({ match }) => {
  const workspaceId = parseInt(match.params.organisationId, 10);

  return (<ModularPage
    pageContent={<GatewayContent_NEON workspaceId={workspaceId} />}
    navigationContent={<MemberAppNavigation_Populated_NEON currentPage="workspace" />}
  />);
};

Gateway.propTypes = {
  match: shape({
    params: shape({
      organisationId: string.isRequired
    }).isRequired
  }).isRequired
};
