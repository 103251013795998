import { ModularPage } from '@paddl/storybook';
import { MemberAppNavigation_Populated_NEON } from '@paddl/utils-react';
import React from 'react';

export const NotFound = () => {
  return <ModularPage
    pageContent={null}
    navigationContent={<MemberAppNavigation_Populated_NEON currentPage="workspace" />}
  />;
};
